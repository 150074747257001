import React from "react";
import { MultiselectWithTags } from "./multi-select";
import { useQuery } from "@tanstack/react-query";
import report from "../../api/report";
import { useDebounce } from "../utils/use-debonce";

type SourceFilterProps = {
  filter: {
    type: string;
    column: string;
    label: string;
    option_label?: string;
    option_value?: string;
    source?: string;
  };
  onChangeFilter: (column, value) => void;
};

export default function SourceFilter({ filter, onChangeFilter }: SourceFilterProps) {
  const [_, setSelected] = React.useState([]); // [string, string, ...]
  const [search, setSearch] = React.useState("");

  const source = filter.source;
  const key = filter.option_value;
  const label = filter.option_label;
  const debounceQuery = useDebounce(search, 300);

  const { data, isLoading } = useQuery({
    queryKey: [filter.column, source, debounceQuery],
    queryFn: () => report.getSource(source, debounceQuery),
  });
  const onChange = (selectedOptions: string[]) => {
    setSelected(selectedOptions);
    onChangeFilter(filter.column, selectedOptions);
  };

  const options =
    data?.map((item) => {
      return {
        value: item[key],
        children: item[label],
      };
    }) || [];

  return (
    <MultiselectWithTags
      isLoading={isLoading}
      searchValue={search}
      onChangeSearch={setSearch}
      placeholder={`Select ${filter.label}`}
      options={options}
      onChangeSelectedOptions={onChange}
    />
  );
}
