import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./screens/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import "./style.css";

/* global document, Office, module, require */

const title = "Contoso Task Pane Add-in";

const rootElement: HTMLElement = document.getElementById("container");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <FluentProvider theme={webLightTheme}>
      <App />
    </FluentProvider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./screens/App", () => {
    const NextApp = require("./screens/App").default;
    root.render(NextApp);
  });
}
