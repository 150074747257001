import React from "react";
import DateRangeFilter from "./date-range-filter";
import NumberInputFilter from "./number-input-filter";
import TextInputFilter from "./text-input-filter";
import SelectFilter from "./select-filter";
import SourceFilter from "./source-filter";

type FiltersProps = {
  filters: {
    type: string;
    column: string;
    label: string;
    options?: any[];
  }[];
  onChangeFilter: (column: string, value: any) => void;
};

export default function Filters({ filters, onChangeFilter }: FiltersProps) {

  return (
    <div>
      <div>
        <h2>Filters</h2>
        <div>
          {filters.map((filter, index) => {
            return (
              <div key={index}>
                <h3>{filter.label}</h3>
                {filter.type === "number" && <NumberInputFilter onChangeFilter={onChangeFilter} filter={filter} />}
                {filter.type === "string" && <TextInputFilter onChangeFilter={onChangeFilter} filter={filter} />}
                {filter.type === "date" && <DateRangeFilter onChangeFilter={onChangeFilter} filter={filter} />}
                {filter.type === "options" && <SelectFilter onChangeFilter={onChangeFilter} filter={filter} />}
                {filter.type === "source" && <SourceFilter onChangeFilter={onChangeFilter} filter={filter} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
