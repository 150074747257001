import { axiosInstance } from "./axios-interceptors";

const auth = {
  login: async (data: { email: string; password: string }) => {
    return (await axiosInstance.post("/auth/login", data)).data;
  },
  authorize: async () => {
    return axiosInstance.get("/auth/authorize");
  },
  logout: async () => {
    return axiosInstance.post("/auth/logout");
  },
  refreshToken: async () => {
    return axiosInstance.post("/auth/refresh");
  },
};

export default auth;
