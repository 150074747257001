import { axiosInstance } from "./axios-interceptors";

const auth = {
  getSource: async (source, query) => {
    return (
      await axiosInstance.get(`/${source}/search`, {
        params: {
          limit: 20,
          query,
        },
      })
    ).data;
  },
  getClientEntity: async (query) => {
    return (
      await axiosInstance.get("/client-entities/search", {
        params: {
          limit: 20,
          query,
        },
      })
    ).data;
  },
  getVessel: async (query) => {
    return (
      await axiosInstance.get("/vessels/search", {
        params: {
          limit: 20,
          query,
        },
      })
    ).data;
  },
  getReport: async (query) => {
    return (
      await axiosInstance.get("/pivot-report", {
        params: {
          limit: 999999,
          page: 1,
          "source-type": "finance",
          ...query,
        },
      })
    ).data;
  },
  getCPStatus: async () => {
    return (await axiosInstance.get("/pivot-report/get-cp-status")).data;
  },
  getTemplates: async () => {
    return (
      await axiosInstance.get("/report-templates/get-by-roles", {
        params: {
          limit: 99999,
        },
      })
    ).data;
  },
  getTemplateDetail: async (id: string) => {
    return (await axiosInstance.get(`/report-templates/${id}`)).data;
  },
  getData: async (id: string, filter: any) => {
    return (
      await axiosInstance.post(`/report-templates/${id}/get-data`, {
        filter,
        limit: 999999,
      })
    ).data;
  },
};

export default auth;
