export const convertHeader = (key: string) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getHeaders = (data: any) => {
  if (data.length === 0) return [];
  const obj = data[0];
  const headers = Object.keys(obj);
  return headers.map((header) => {
    return {
      header: convertHeader(header),
      key: header,
      type: "string",
    };
  });
};
