import * as React from "react";
import {
  makeStyles,
  shorthands,
  Input,
  Button,
  Field,
  Spinner,
  useId,
  useToastController,
  Toast,
  ToastTitle,
  Toaster,
} from "@fluentui/react-components";
import { zodResolver } from "@hookform/resolvers/zod";
import auth from "../../api/auth";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useGlobalStore } from "../../store/global-store";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  input: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
  button: {
    width: "100%",
    height: "36px",
  },
});

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

export const Login = () => {
  const navigate = useNavigate();
  const isLogin = useGlobalStore((state) => state.isLogin);
  const onChangeLogin = useGlobalStore((state) => state.onChangeLogin);
  const styles = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const form = useForm({ resolver: zodResolver(schema) });

  const {
    formState: { errors },
  } = form;

  React.useEffect(() => {
    if (isLogin) {
      navigate("/dashboard");
    }
  }, [isLogin]);

  const onsubmit = async (data) => {
    try {
      setIsLoading(true);
      const rs = await auth.login(data);
      const { token } = rs;
      onChangeLogin(token);
      navigate("/dashboard");
      setIsLoading(false);
    } catch (error) {
      let message;
      if (error.response?.data) {
        message = error.response.data.errors ? error.response.data.errors[0] : "Something went wrong";
      }
      dispatchToast(
        <Toast>
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Toaster toasterId={toasterId} />
      <h2 className={styles.header}>Login to Opswiz</h2>
      <form className={styles.formContainer} onSubmit={form.handleSubmit(onsubmit)}>
        <Field
          label="Email"
          validationState={errors.email ? "error" : "none"}
          validationMessage={errors.email ? "Please enter a valid email" : undefined}
        >
          <Input {...form.register("email")} />
        </Field>

        <Field
          label="Password"
          validationState={errors.password ? "error" : "none"}
          validationMessage={errors.password ? "Please enter a password" : undefined}
        >
          <Input type="password" {...form.register("password")} />
        </Field>

        <div>
          <Button type="submit" appearance="primary" className={styles.button}>
            {isLoading ? <Spinner size="extra-tiny" /> : "Login"}
          </Button>
        </div>
      </form>
    </div>
  );
};
