import React from "react";
import { MultiselectWithTags } from "./multi-select";

type FiltersProps = {
  filter: {
    type: string;
    column: string;
    label: string;
    options?: any[];
  };
  onChangeFilter: (column: string, value: any) => void;
};

export default function SelectFilter({ filter, onChangeFilter }: FiltersProps) {
  const [selected, setSelected] = React.useState([]); // [string, string, ...]
  const [search, setSearch] = React.useState("");
  const onChange = (selectedOptions: string[]) => {
    setSelected(selectedOptions);
    onChangeFilter(filter.column, selectedOptions);
  };

  const options =
    filter.options.map((item) => {
      return {
        value: item,
        children: item,
      };
    }) || [];

  return (
    <MultiselectWithTags
      isLoading={false}
      searchValue={search}
      onChangeSearch={setSearch}
      placeholder={`Select ${filter.label}`}
      options={options}
      onChangeSelectedOptions={onChange}
    />
  );
}
