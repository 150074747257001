/* global Excel console */
import { format, parse } from "date-fns";

export const deleteTable = async () => {
  try {
    Excel.run(async (context) => {
      const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
      currentWorksheet.getRange().clear();
      return context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};

const convertDate = (date: string) => {
  return date ? format(parse(date, "dd/MM/yyyy", new Date()), "MM/dd/yyyy") : date;
};

const getRange = (rows: number): string => {
  const start = "A1";
  let end = "";

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const firstLetterIndex = Math.floor((rows - 1) / 26);
  const secondLetterIndex = (rows - 1) % 26;

  if (firstLetterIndex > 0) {
    end = letters[firstLetterIndex - 1];
  }
  end += letters[secondLetterIndex] + "1";

  return `${start}:${end}`;
};

const insertData = async (headers, data: any, tableName: string) => {
  try {
    Excel.run(async (context) => {
      const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();

      const excelRange = getRange(headers.length);

      const expensesTable = currentWorksheet.tables.add(excelRange, true /*hasHeaders*/);
      expensesTable.name = tableName;

      expensesTable.getHeaderRowRange().values = [headers.map((item) => item.header)];

      const rows = data.map((item) => {
        return headers.map((row) => {
          if (row.type === "date") {
            return convertDate(item[row.key]);
          }
          return item[row.key];
        });
      });

      expensesTable.rows.add(null /*add at the end*/, rows);

      if (data.length === 0) return Promise.resolve();

      const range = currentWorksheet.getRange(excelRange);

      range.format.autofitColumns();

      return context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};

export default insertData;
