/* eslint-disable no-undef */
import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import { useGlobalStore } from "../store/global-store";

export const API_BASE_URL = process.env.API_BASE_URL;
const axiosConfig: AxiosRequestConfig = {
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000 * 60 * 10,
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
  (config) => {
    const access_token = useGlobalStore.getState().token;
    if (access_token) config.headers.Authorization = `Bearer ${access_token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const logout = useGlobalStore.getState().logout;
    const isLogin = useGlobalStore.getState().isLogin;
    if ((error.response.status === 401 || error.response.status === 400) && isLogin) {
      logout();
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
