import * as React from "react";
import { Button, Combobox, Label, makeStyles, Option, shorthands, tokens, useId } from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.gap("2px"),
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    flexWrap: "wrap",
    gridGap: tokens.spacingHorizontalXXS,
  },
  label: {
    fontWeight: "bold",
    display: "flex",
    marginBottom: "6px",
  },
  listbox: {
    maxHeight: "200px",
  },
});

type MultiselectWithTagsProps = {
  options: {
    children: string;
    value: string;
  }[];
  emptyMessage?: string;
  label?: string;
  isLoading: boolean;
  placeholder: string;
  searchValue: string;
  onChangeSearch: (search: string) => void;
  onChangeSelectedOptions: (selectedOptions: string[]) => void;
};

export const MultiselectWithTags = (props: MultiselectWithTagsProps) => {
  const comboId = useId("combo-multi");
  const selectedListId = `${comboId}-selection`;
  const { options, placeholder, label, isLoading, searchValue, onChangeSearch, onChangeSelectedOptions } = props;

  // refs for managing focus when removing tags
  const selectedListRef = React.useRef<HTMLUListElement>(null);
  const comboboxInputRef = React.useRef<HTMLInputElement>(null);

  const styles = useStyles();

  const [selectedOptions, setSelectedOptions] = React.useState<
    {
      children: string;
      value: string;
    }[]
  >([]);

  const onSelect: ComboboxProps["onOptionSelect"] = (_, data) => {
    const optionValue = data.optionValue;
    const option = options.find((o) => o.value === optionValue);
    const newSelectedOptions = [...selectedOptions, option];
    setSelectedOptions(newSelectedOptions);
    // setSelectedOptions(data.selectedOptions);
    onChangeSelectedOptions(data.selectedOptions);
  };

  const onTagClick = (option: string, index: number) => {
    const newSelectedOptions = selectedOptions.filter((o) => o.value !== option);
    setSelectedOptions(newSelectedOptions);
    onChangeSelectedOptions(newSelectedOptions.map((o) => o.value));

    const indexToFocus = index === 0 ? 1 : index - 1;
    const optionToFocus = selectedListRef.current?.querySelector(`#${comboId}-remove-${indexToFocus}`);
    if (optionToFocus) {
      (optionToFocus as HTMLButtonElement).focus();
    } else {
      comboboxInputRef.current?.focus();
    }
  };

  const _onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(event.target.value);
  };

  // const getLabel = (option: string) => {
  //   return options.find((o) => o.value === option)?.children;
  // };

  const labelledBy = selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const availableOptions = options.filter(
    (o) =>
      o.children.toLowerCase().includes(searchValue.toLowerCase()) &&
      !selectedOptions.map((v) => v.value).includes(o.value)
  );

  return (
    <div className={styles.root}>
      {label && <Label className={styles.label}>{label}</Label>}
      {selectedOptions.length ? (
        <ul id={selectedListId} className={styles.tagsList} ref={selectedListRef}>
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {selectedOptions.map((option, i) => (
            <li key={option.value}>
              <Button
                size="small"
                shape="circular"
                appearance="primary"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option.value, i)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
              >
                {option.children}
              </Button>
            </li>
          ))}
        </ul>
      ) : null}
      <Combobox
        aria-labelledby={labelledBy}
        multiselect={true}
        placeholder={placeholder}
        selectedOptions={selectedOptions.map((v) => v.value)}
        listbox={{ className: styles.listbox }}
        onOptionSelect={onSelect}
        ref={comboboxInputRef}
        value={searchValue}
        onChange={_onChangeSearch}
      >
        {availableOptions.map((option) => (
          <Option value={option.value} key={option.value}>
            {option.children}
          </Option>
        ))}
        {availableOptions.length === 0 && !isLoading && (
          <Option value="no-options" key="no-options" disabled>
            No options available
          </Option>
        )}
        {isLoading && (
          <Option value="loading" key="loading" disabled>
            Loading...
          </Option>
        )}
      </Combobox>
    </div>
  );
};
