import { Field, makeStyles, shorthands } from "@fluentui/react-components";
import React from "react";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Dismiss24Filled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("20px"),
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  datePicker: {
    position: "relative",
  },
  control: {
    width: "100%",
  },
  closeWrapper: {
    position: "absolute",
    top: "1px",
    right: "2px",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    cursor: "pointer",
  },
  icon: {
    width: "14px",
    height: "14px",
  },
  col: {
    flexGrow: 1,
    position: "relative",
  },
});

type DateRangeFilterProps = {
  filter: {
    type: string;
    column: string;
    label: string;
  };
  onChangeFilter: (column, value) => void;
};

export default function DataRangeFilter({ filter, onChangeFilter }: DateRangeFilterProps) {
  const [value, setValue] = React.useState({
    from: null,
    to: null,
  });
  const styles = useStyles();

  const onChangeValue = (key, value) => {
    setValue((prev) => {
      onChangeFilter(filter.column, { ...prev, [key]: value });
      return { ...prev, [key]: value };
    });
  };

  return (
    <div className={styles.row}>
      <Field className={styles.col} label="Start date">
        <div className={styles.datePicker}>
          <DatePicker
            value={value.from}
            onSelectDate={(data) => onChangeValue("from", data)}
            className={styles.control}
            placeholder="Select a date..."
          />
          {value.from && (
            <div className={styles.closeWrapper} onClick={() => onChangeValue("from", null)}>
              <Dismiss24Filled className={styles.icon} />
            </div>
          )}
        </div>
      </Field>
      <Field className={styles.col} label="End date">
        <div className={styles.datePicker}>
          <DatePicker
            value={value.to}
            onSelectDate={(value) => onChangeValue("to", value)}
            className={styles.control}
            placeholder="Select a date..."
          />
          {value.to && (
            <div className={styles.closeWrapper} onClick={() => onChangeValue("to", null)}>
              <Dismiss24Filled className={styles.icon} />
            </div>
          )}
        </div>
      </Field>
    </div>
  );
}
