import { Input, makeStyles, shorthands } from "@fluentui/react-components";
import React from "react";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.gap("2px"),
  },
});

type InputFilterProps = {
  filter: {
    type: string;
    column: string;
    label: string;
  };
  onChangeFilter: (column, value) => void;
};

export default function TextInputFilter({ filter, onChangeFilter }: InputFilterProps) {
  const styles = useStyles();

  const [value, setValue] = React.useState("");

  const onChangeValue = (value) => {
    setValue(value);
    onChangeFilter(filter.column, value);
  };

  return (
    <div className={styles.root}>
      <Input placeholder={`Enter ${filter.label}`} type={"text"} value={value} onChange={(e) => onChangeValue(e.target.value)} />
    </div>
  );
}
